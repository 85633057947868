<template>
  <section class="product-card">
    <editCard
      v-if="editable"
      :modal_name="'gcmeb' + sectionTitle"
      :entitytypes="['products']"
      @edit-card-com="changeOption($event)"
      :option="options"
      :parentData="pdata"

    />
    <div class="row product-item-wrapper">
      <div :class="{ scrollcards: scrollcards }" :id="id">
        <div
          class="
            col-lg-4 col-md-6 col-sm-6 col-12
            mx-2
            product-item
            stretch-card
          "
          v-for="(item, itemID) in items.block.data"
          :key="itemID"
        >
          <div class="card">
            <div class="card-body">
              <div class="product-img-outer">
                <!-- <img class="product_image" v-if="item.linkedimagesurls" :src="item.linkedimagesurls['originalImage']" alt=""> -->
                <img
                  class="product_image"
                  :src="item['card-image-url']"
                  alt="prduct image"
                />
              </div>
              <p class="product-title" v-if="isDisplay('title')">
                {{ item[title] }}
              </p>
              <p class="product-price" v-if="isDisplay('prix')">
                {{ item[price] }} €
              </p>
              <p class="product-description" v-if="isDisplay('description')">
                {{ item[description] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from "axios";
import productjson from "../../pages/json/product-test.json";
import editCard from "./edit-card.vue";

export default {
  name: "product-card",
  components: {
    editCard,

  },
  data() {
    return {
      items: [],
      selectedDisplay: this.display,
      coll_Checked:[],
      sel_entity:[],
      scroll_cards:false,
      options: {
        collection: false,
        display: [
          { titre: "Description", value: "description" },
          { titre: "Titre", value: "title" },
          { titre: "Prix", value: "prix" },
        ],
      },
      
     pdata:{
       selentity:this.entitytype,
       sdisplay:this.display,
       scroll:this.scrollcards
     }
    };
  },
  props: {
    id: String,
    scrollcards: Boolean,
    entitytype: String,
    card: Array,
    title: String,
    description: String,
    price: String,
    column: String,
    sectionTitle: String,
    editable: Boolean,
    display: null,
  },
  methods: {
    getEntitytype() {
      // if (this.entitytype) {
      //   axios
      //     .get(`https://sites.comolapapaya.com/${this.entitytype}`)
      //     .then((resEntitytype) => {
      //       this.items = resEntitytype.data.result;
      //     })
      //     .catch((errEntitytype) => {
      //       console.log(errEntitytype);
      //     });
      // } else if (this.card) {
      //   this.items = this.card;
      // } else {
      //   this.items = [];
      // }
      this.items = productjson;
      console.log("products :" + productjson);
    },
    isDisplay(el) {
      if (this.selectedDisplay.includes(el)) {
        return true;
      } else {
        return false;
      }
    },
    changeOption(data) {
      this.selectedDisplay = data["display"],
      this.coll_Checked = data["collection"],
      this.sel_entity = data["entity"],
      this.scroll_cards = data["scroll"];
      this.saveCard();
    },
    //scrollcards
    scrollright() {
      console.log(this.id);
      document.getElementById(`${this.id}`).scrollLeft += 1000;
    },
    scrollleft() {
      console.log(this.id);
      document.getElementById(`${this.id}`).scrollLeft -= 1000;
    },
    deleteCard(item) {
      let index = this.items.indexOf(item);
      console.log(index);
      this.items.splice(index, 1);
    },
    // edit(item) {
    //     this.$emit('edit-card', {
    //         item: item
    //     })
    // }
    saveCard() {
      //si collection
      this.$emit("saveCardproduct", {
        section: {
          title: "ajout de collection",
          blocks: [
            {
              entitytype: "products",
              element: "product-card",
              data: {
                scrollcards: this.scroll_cards,
                display: this.selectedDisplay,
                card: [],
              },
            },
          ],
        },
        blocks: [
          {
            entitytype: "products",
            element: "product-card",
            data: {
              scrollcards: this.scroll_cards,
              display: this.selectedDisplay,
              card: [],
            },
          },
        ],
      });
    },
  },
  created() {
    this.getEntitytype();
    // this.selectedDisplay = this.display;
  },
};
</script>

<style scoped>
.product-item {
  display: inline-block;
  text-decoration: none;
  height: auto;
  width: 288px;
}

/* scrollcards */
.scrollcards {
  /* background-color: #fff; */
  overflow-x: auto;
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

div.scrollcards .card {
  display: inline-block;
  /* padding: 14px; */
  text-decoration: none;
  height: auto;
  width: 300px;
}

.carousel-control-prev-icon {
  background-image: url("../../assets/images/import/arrow-preview.png");
  width: 50px;
  height: 50px;
  margin: 0px 81px 0px -122px;
}
.carousel-control-next-icon {
  background-image: url("../../assets/images/import/arrow-next.png");
  width: 50px;
  height: 50px;
  margin: 0px -121px 0px 79px;
}

img {
  width: auto;
  height: 200px;
  object-fit: cover;
}
</style>